<template>
    <div class="my-5">
        <h2 class="subheading grey--text" my-5> {{ $t('Print ticket signature') }} </h2>
        <v-container fluid fill-height class="my-5">
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md6 lg10>
                <v-card class="pa-3">
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="4"/>
                            <v-col>
                                <div v-if="savedImageExists && !image && !savedImageBase64">
                                    <v-btn
                                        color="green darken-1"
                                        class="white--text"
                                        @click="downloadImage"
                                    >
                                        {{ $t('Reveal Signature Image') }}
                                    </v-btn>
                                </div>
                                <div v-if="!savedImageExists" class="text-center">
                                    <h3 class="subheading grey--text"> {{ $t('No saved signature image') }} </h3>
                                </div>
                                <div v-if="image || savedImageBase64">
                                    <v-img
                                     :src="imageUrl"
                                    >                                    
                                    </v-img>
                                </div>
                            </v-col>
                            <v-col cols="4"/>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                            <v-file-input
                                v-model="image"
                                :rules="validRules"
                                @change="onFileSelected"
                                show-size
                                accept="image/png"
                                :label="$t('Select an image')"
                            ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3"></v-col>
                            <v-col>
                                <div class="text-center">
                                    <v-btn v-if="image"
                                        color="green darken-1"
                                        class="white--text"
                                        @click="confirmSaveImage"
                                        :loading="loading"
                                    >{{ $t('Update image') }}
                                    </v-btn>
                                    &nbsp;&nbsp;
                                    <v-btn v-if="savedImageExists"
                                        color="red darken-1"
                                        class="white--text"
                                        @click="signatureImageDeleteConfirm = true"
                                    >{{ $t('Delete saved image') }}
                                    </v-btn>                                
                                </div>
                            </v-col>
                            <v-col cols="3"></v-col>
                        </v-row>
                    </v-form>
                </v-card>
                </v-flex>
            </v-layout>
        </v-container>

        <!-- image update confirm -->
        <v-dialog v-model="signatureImageSaveConfirm" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">{{ $t('Confirm') }}</v-card-title>
                <v-card-text>{{ $t('Are you sure to update signature image?') }}</v-card-text>
                <v-card-actions>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="signatureImageSaveConfirm = false"
                    >
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="saveImage"
                    >
                        {{ $t('Update') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- image update confirm -->

        <!-- image delete confirm -->
        <v-dialog v-model="signatureImageDeleteConfirm" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">{{ $t('Confirm') }}</v-card-title>
                <v-card-text>{{ $t('Are you sure to delete signature image?') }}</v-card-text>
                <v-card-actions>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="signatureImageDeleteConfirm = false"
                    >
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="deleteImage"
                    >
                        {{ $t('Delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- image delete confirm -->

        <!-- error snackbar -->
        <v-snackbar color="#D50000" v-model="errorSnackbar" :timeout="4000">
            <span>{{ $t('An error occured') }}</span>
            <v-btn icon dark @click="errorSnackbar = false">
                <v-icon color="#FFFFFF">close</v-icon>
            </v-btn>
        </v-snackbar>
        <!-- error snackbar -->

        <!-- save result snackbar -->
        <v-snackbar v-model="saveResultSnackbar" :timeout="4000">
            <span>{{ $t('The signature image successfully saved') }}</span>
            <v-btn icon dark @click="saveResultSnackbar = false">
                <v-icon color="#FFFFFF">close</v-icon>
            </v-btn>
        </v-snackbar>
        <!-- save result snackbar -->

        <!-- delete result snackbar -->
        <v-snackbar v-model="deleteResultSnackbar" :timeout="4000">
            <span>{{ $t('The signature image successfully deleted') }}</span>
            <v-btn icon dark @click="deleteResultSnackbar = false">
                <v-icon color="#FFFFFF">close</v-icon>
            </v-btn>
        </v-snackbar>
        <!-- delete result snackbar -->
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            valid: true,
            loading: false,
            image: null,
            savedImageBase64: null,
            signatureImageSaveConfirm: false,
            signatureImageDeleteConfirm: false,
            savedImageExists: false,
            successResult: null,
            saveResultSnackbar: false,
            deleteResultSnackbar: false,
            errorResult: null,
            errorSnackbar: false,
            validRules: [v => !!v || this.$t('Select an image')],
        }
    },
    methods: {
        ...mapActions({
            invokeGetSignatureExists: 'signature/getSignatureExists',
            invokeUploadImage: 'signature/uploadImage',
            invokeDeleteImage: 'signature/deleteImage',
            invokeDownloadImage: 'signature/downloadImage'
        }),
        onFileSelected(f) {
            this.selectedFileName = f;
        },
        confirmSaveImage() {
            if (!this.$refs.form.validate()) return;
            this.signatureImageSaveConfirm = true;
        },
        saveImage() {
            if (!this.$refs.form.validate()) return;

            const formData = new FormData()
            formData.append('File', this.image)

            this.loading=true
            this.signatureImageSaveConfirm = false;
            this.successResult = null
            this.errorResult = null

            this.invokeUploadImage(formData)
                .finally(() => { this.loading = false })
                .then(r => {
                    this.saveResultSnackbar = true
                    this.successResult = r
                    this.savedImageExists = true
                })
                .catch(e => {
                    this.errorSnackbar = true
                    this.errorResult = e
                })
        },
        deleteImage() {
            this.signatureImageDeleteConfirm = false;
            this.successResult = null
            this.errorResult = null

            this.invokeDeleteImage()
                .then(r => {
                    this.deleteResultSnackbar = true
                    this.successResult = r
                    this.image = null
                    this.savedImageBase64 = null
                    this.savedImageExists = false
                })
                .catch(e => {
                    this.errorSnackbar = true
                    this.errorResult = e
                })
        },
        downloadImage() {
            this.errorResult = null

            this.invokeDownloadImage()
                .then(r => {
                    this.savedImageBase64 = r.data
                })
                .catch(e => {
                    this.errorSnackbar = true
                    this.errorResult = e
                })
        },
        ensureSignatureImageExists() {
            this.invokeGetSignatureExists()
            .then(result =>{
                this.savedImageExists = !!result.exists
            })
        }
    },
    computed: {
        imageUrl() {
            return this.image
                        ? URL.createObjectURL(this.image) 
                            : this.savedImageBase64
                                ? `data:image/png;base64,${this.savedImageBase64}`
                                : ""
        }
    },
    mounted() {
        this.ensureSignatureImageExists()
    }
}
</script>
