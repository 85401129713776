import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue'
import TicketCreate from '../pages/Ticket/Create.vue'
import TicketList from '../pages/Ticket/List.vue'
import TicketImport from '../pages/Ticket/Import.vue'
import TicketCreateAndPrint from '../pages/Ticket/CreateAndPrint.vue'
import UserSignature from '../pages/User/Signature.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/',
    name: 'default',
    redirect: '/ticket/create'
  },
  {
    path: '/ticket/list',
    name: 'ticketList',
    component: TicketList
  },
  {
    path: '/ticket/create',
    name: 'ticketCreate',
    component: TicketCreate
  },
  {
    path: '/ticket/create-print',
    name: 'ticketCreateAndPrint',
    component: TicketCreateAndPrint
  },
  {
    path: '/ticket/import',
    name: 'ticketImport',
    component: TicketImport
  },
  {
    path: '/user/signature',
    name: 'userSignature',
    component: UserSignature
  },
  {
    path: '*',
    redirect: { name: 'default' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => !record.meta.allowAnonymous)) {
    store
      .dispatch('auth/verifySignedIn')
      .then(() => {
        next()
      })
      .catch(() => {
        store.dispatch('auth/signOut').then(() => next('/login'))
      })
  } else {
    next()
  }
})

export default router
