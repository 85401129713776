import axios from 'axios'

const actions = {
    getSignatureExists(context) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${window.__env.apiUrl}/api/users/me/signature/exists`)
                .then(r => resolve(r.data))
                .catch(e => reject(e))
        })
    },
    uploadImage(context, formData) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${window.__env.apiUrl}/api/users/me/signature`, formData)
                .then(r => resolve(r.data))
                .catch(e => reject(e))
        })
    },
    deleteImage(context) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${window.__env.apiUrl}/api/users/me/signature`)
                .then(r => resolve(r.data))
                .catch(e => reject(e))
        })
    },
    downloadImage() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${window.__env.apiUrl}/api/users/me/signature`)
                .then(r => resolve(r.data))
                .catch(e => reject(e))
        })
    }
}

export default {
    actions,
    namespaced: true
}