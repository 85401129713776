import axios from 'axios'

const state = {
  token: localStorage.getItem('jwt-token') || ''
}

const getters = {
  accessToken: state => state.token
}

const mutations = {
  setToken: (state, tokenObj) => {
    if (tokenObj) {
      localStorage.setItem('jwt-token', JSON.stringify(tokenObj))
      axios.defaults.headers.common['Authorization'] = `Bearer ${tokenObj.accessToken}`
    }
  },
  clearToken: () => {
    localStorage.removeItem('jwt-token')
    delete axios.defaults.headers.common['Authorization']
  }
}

const actions = {
  signIn({ commit, dispatch }, { userEmail: userName, password: pwd }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${window.__env.apiUrl}/api/auth/signin`, { userName, pwd })
        .then(response => {
          if (response.data && response.data.accessToken) {
            commit('setToken', {
              accessToken: response.data.accessToken,
              exp: new Date().getTime() + +response.data.ttl * 60 * 1000
            })
            commit(
              'setHost',
              { name: response.data.hostName, id: response.data.hostId },
              { root: true }
            )
            resolve()
          } else {
            reject()
          }
        })
        .catch(error => {
          dispatch('signOut')
          reject(error)
        })
    })
  },
  signOut({ commit }) {
    commit('clearToken')
    commit('clearHost', null, { root: true })
  },
  verifySignedIn() {
    const ifAccessTokenExpired = exp => exp - new Date().getTime() < 0

    var existingJwtToken = localStorage.getItem('jwt-token')

    let tokenExists = existingJwtToken && !ifAccessTokenExpired(JSON.parse(existingJwtToken).exp)

    return existingJwtToken && !ifAccessTokenExpired(JSON.parse(existingJwtToken).exp)
      ? Promise.resolve(true)
      : Promise.reject('You are not signed in to the system.')
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
