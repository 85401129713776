<template>
  <div class="my-5">
    <h2 class="subheading grey--text" my-5>Datei hochladen</h2>
    <v-container fluid fill-height class="my-5">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg10>
          <v-card class="pa-3">
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="file"
                    :rules="validRules"
                    show-size
                    accept=".csv"
                    :label="$t('File Input')"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5"></v-col>
                <v-col>
                  <v-btn
                    color="#d0021b"
                    class="white--text"
                    @click="submit"
                    :loading="loading"
                  >{{ $t('Next') }}</v-btn>
                </v-col>
                <v-col cols="5"></v-col>
              </v-row>
            </v-form>
          </v-card>
          <!-- error snackbar -->
          <v-snackbar color="#D50000" v-model="errorSnackbar" :timeout="4000">
            <span>{{ $t('An error occured on importing the file') }}</span>
            <v-btn icon dark @click="errorSnackbar = false">
              <v-icon color="#FFFFFF">close</v-icon>
            </v-btn>
          </v-snackbar>
          <!-- error snackbar -->
          <!-- result snackbar -->
          <v-snackbar v-model="resultSnackbar" :timeout="4000">
            <span>{{ $t('Data successfully imported') }}</span>
            <v-btn icon dark @click="resultSnackbar = false">
              <v-icon color="#FFFFFF">close</v-icon>
            </v-btn>
          </v-snackbar>
          <!-- result snackbar -->
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      valid: true,
      loading: false,
      validRules: [v => !!v || this.$t('Select a file to upload')],
      file: null,
      successResult: null,
      resultSnackbar: false,
      errorResult: null,
      errorSnackbar: false
    }
  },
  methods: {
    ...mapActions({ performUpload: 'ticket/uploadImportData' }),
    submit() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true
      this.successResult = null
      this.errorResult = null

      const formData = new FormData()
      formData.append('File', this.file)

      this.performUpload(formData)
        .finally(() => (this.loading = false))
        .then(result => {
          this.resultSnackbar = true
          this.successResult = result
        })
        .catch(error => {
          this.errorSnackbar = true
          this.errorResult = error
        })
    }
  }
}
</script>