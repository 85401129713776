<template>
  <div class="my-5">
    <h2 class="subheading grey--text" my-5>Neues Bern Ticket lösen</h2>
    <v-container fluid fill-height class="my-5">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg10>
          <v-card class="pa-3">
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="ticket.guestName"
                    :rules="nameRules"
                    :label="$t('Guest First name and Second name')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="ticket.bookingNumber"
                    :rules="
                      ticket.otaNumber == '' || ticket.otaNumber.trim() == ''
                        ? bookingNumberRules
                        : []
                    "
                    :label="$t('Booking Number')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="ticket.otaNumber"
                    :label="$t('OTA Number')"
                    :rules="
                      ticket.bookingNumber == '' || ticket.bookingNumber.trim() == ''
                        ? otaNumberRules
                        : []
                    "
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    ref="menuFrom"
                    v-model="menuFromShow"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formatValidFrom"
                        :label="$t('Valid From')"
                        persistent-hint
                        :rules="validFromRules"
                        readonly
                        required
                        prepend-icon="date_range"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="ticket.validFrom"
                      locale="de-CH"
                      :min="currentDate"
                      no-title
                      @input="menuFromShow = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menuTo"
                    v-model="menuToShow"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formatValidTo"
                        :label="$t('Valid To')"
                        persistent-hint
                        :rules="validToRules"
                        required
                        readonly
                        prepend-icon="date_range"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="ticket.validTo"
                      locale="de-CH"
                      :min="minValidTo"
                      no-title
                      @input="menuToShow = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="ticket.ticketsAmount"
                    :rules="ticketsAmountRules"
                    :label="$t('Ticket Amount')"
                    type="number"
                    min="1"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5"></v-col>
                <v-col>
                  <v-btn color="#d0021b" class="white--text" @click="submit" :loading="loading"
                    >{{ $t('Generate ticket') }}</v-btn
                  >
                </v-col>
                <v-col cols="5"></v-col>
              </v-row>
            </v-form>
          </v-card>
          <!-- error snackbar -->
          <v-snackbar color="#D50000" v-model="errorSnackbar" :timeout="4000">
            <span>{{ $t('An error occured') }}</span>
            <v-btn icon dark @click="errorSnackbar = false">
              <v-icon color="#FFFFFF">close</v-icon>
            </v-btn>
          </v-snackbar>
          <!-- error snackbar -->
        </v-flex>
      </v-layout>
    </v-container>

    <!-- result dialog -->
    <v-row justify="center">
      <v-dialog
        v-model="showCodesDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="#EEEEEE">
            <v-btn icon dark @click="showCodesDialog = false">
              <v-icon color="#000000">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 lg3 v-for="codeCard in codes" :key="codeCard.code">
                <v-card flat class="text-xs-center ma-3 py-5" color="#25416e">
                  <v-card-text>
                    <div class="headline white--text text-center">{{ codeCard.code }}</div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- result dialog -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TicketCreate',
  data() {
    return {
      valid: true,
      menuFromShow: false,
      menuToShow: false,
      showCodesDialog: false,
      loading: false,
      errorSnackbar: false,
      notRequired: false,
      ticket: {
        guestName: '',
        bookingNumber: '',
        otaNumber: '',
        validFrom: new Date().toISOString().substr(0, 10),
        validTo: new Date().toISOString().substr(0, 10),
        ticketsAmount: 1
      },
      nameRules: [v => !!v || this.$t('The guest name must be provided')],
      bookingNumberRules: [v => !!v || this.$t('The Booking Number must be provided')],
      otaNumberRules: [v => !!v || this.$t('The OTA Number must be provided')],
      validFromRules: [v => !!v || this.$t('Valid From must be provided')],
      validToRules: [v => !!v || this.$t('Valid To must be provided')],
      ticketsAmountRules: [v => +v > 0 || this.$t('Amount of tickets must be provided')],
      codes: []
    }
  },
  computed: {
    formatValidFrom() {
      return this.formatDate(this.ticket.validFrom)
    },
    formatValidTo() {
      return this.formatDate(this.ticket.validTo)
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    minValidTo() {
      return this.ticket.validFrom
    }
  },
  methods: {
    ...mapActions({
      create: 'ticket/create'
    }),
    submit() {
      this.codes = []

      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true

      this.create(this.ticket)
        .finally(() => (this.loading = false))
        .then(r => {
          this.codes = r
          this.showCodesDialog = true
        })
        .catch(() => (this.errorSnackbar = true))
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    allowedFromDates(val) {
      return new Date(val) >= this.currentDate
    }
  }
}
</script>
