import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import axios from 'axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueI18n from 'vue-i18n'
import translates from './translates.json'

Vue.config.productionTip = false

axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response && error.response.status === 401) {
      store.dispatch('auth/signOut')
      router.push({ name: 'login' })
    }

    return Promise.reject(error.response && error.response.data ? error.response.data : error)
  }
)

Vue.use(VueI18n)

const messages = Object.assign(translates)

const i18n = new VueI18n({
  locale: 'de',
  messages
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
