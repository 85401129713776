<template>
  <div>
    <v-app-bar app :clipped-left="$vuetify.breakpoint.lgAndUp">
      <v-app-bar-nav-icon @click="toggleNavbar" class="grey--text"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        {{
        hostName
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <img src="@/assets/img/bern-logo-splash@2x.png" alt="Bern" width="130" height="40" />
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp">
      <v-list dense>
        <v-subheader>{{ $t("Ticket") }}</v-subheader>
        <v-list-item key="issueTicket" router to="/ticket/create">
          <v-list-item-icon>
            <v-icon>card_travel</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">{{ $t("Issue New Tickets") }}</v-list-item-title>
        </v-list-item>
        <v-list-item key="issueTicket-print" router to="/ticket/create-print">
          <v-list-item-icon>
            <v-icon>print</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">{{ $t("Issue New Ticket and print") }}</v-list-item-title>
        </v-list-item>
        <v-list-item key="importTickets" router to="/ticket/import">
          <v-list-item-icon>
            <v-icon>arrow_downward</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">{{ $t("Import") }}</v-list-item-title>
        </v-list-item>
        <v-list-item key="tickets" router to="/ticket/list">
          <v-list-item-icon>
            <v-icon>list</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">{{ $t('Tickets') }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-subheader>{{ $t("Manage") }}</v-subheader>
        <v-list-item key="signature" router to="/user/signature">
          <v-list-item-icon>
            <v-icon>approval</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">{{ $t('Signature') }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-list dense>
            <v-list-item @click="doSignOut" key="signout">
              <v-list-item-title class="font-weight-bold">{{ $t('Log Out') }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon>exit_to_app</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      drawer: true
    }
  },
  computed: {
    ...mapGetters({
      hostName: 'getHostName'
    })
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut'
    }),
    toggleNavbar() {
      this.drawer = !this.drawer
    },
    doSignOut() {
      this.signOut()
      this.$router.push('/login')
    }
  }
}
</script>

<style></style>
