import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import ticket from "./modules/ticket";
import signature from "./modules/signature"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    host: {}
  },
  getters: {
    getHostName: state => state.host.name || ""
  },
  mutations: {
    setHost: (state, host) => {
      state.host = host;
    },
    clearHost: state => {
      state.host = {};
    }
  },
  actions: {},
  modules: {
    auth,
    ticket,
    signature
  }
});
