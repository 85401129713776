<template>
  <div class="my-5">
    <h2 class="subheading grey--text" my-5>Neues Bern Ticket lösen und ausdrucken</h2>
    <v-container fluid fill-height class="my-5">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg10>
          <v-card class="pa-3">
            <v-form ref="form" v-model="valid">
              <v-form>
                <v-col cols="12">
                  <p class="font-weight-black text-center">
                    {{ $t('A physical Bern Ticket can only be issued for a period of 2 days.') }} <br/>
                    {{ $t('If a Bern Ticket is required for more than 2 days, please contact the Tourist Information.') }}
                  </p>
                </v-col>
              </v-form>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="ticket.guestName"
                    :rules="nameRules"
                    :label="$t('Guest First name and Second name')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="ticket.bookingNumber"
                    :rules="
                      ticket.bookingNumber == '' || ticket.bookingNumber.trim() == ''
                        ? bookingNumberRules
                        : []
                    "
                    :label="$t('Booking Number / OTA Number')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    ref="menuFrom"
                    v-model="menuFromShow"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formatValidFrom"
                        :label="$t('Valid From')"
                        persistent-hint
                        :rules="validFromRules"
                        readonly
                        required
                        prepend-icon="date_range"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="ticket.validFrom"
                      locale="de-CH"
                      :min="currentDate"
                      no-title
                      @input="menuFromShow = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menuTo"
                    v-model="menuToShow"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formatValidTo"
                        :label="$t('Valid To')"
                        persistent-hint
                        :rules="validToRules"
                        required
                        readonly
                        prepend-icon="date_range"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="ticket.validTo"
                      locale="de-CH"
                      :min="minValidTo"
                      :max="maxValidTo"
                      no-title
                      @input="menuToShow = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5"></v-col>
                <v-col>
                  <v-btn color="#d0021b" class="white--text" @click="submit" :loading="loading"
                    >{{ $t('Generate ticket') }}</v-btn
                  >
                </v-col>
                <v-col cols="5"></v-col>
              </v-row>
            </v-form>
          </v-card>
          <!-- error snackbar -->
          <v-snackbar color="#D50000" v-model="errorSnackbar" :timeout="4000">
            <span>{{ $t('An error occured') }}</span>
            <v-btn icon dark @click="errorSnackbar = false">
              <v-icon color="#FFFFFF">close</v-icon>
            </v-btn>
          </v-snackbar>
          <!-- error snackbar -->
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TicketCreateAndPrint',
  data() {
    return {
      valid: true,
      menuFromShow: false,
      menuToShow: false,
      loading: false,
      errorSnackbar: false,
      notRequired: false,
      ticket: {
        guestName: '',
        bookingNumber: '',
        validFrom: this.dateToString(new Date()),
        validTo: this.dateToString(this.addDays(new Date(), 1)),
      },
      nameRules: [v => !!v || this.$t('The guest name must be provided')],
      bookingNumberRules: [v => !!v || this.$t('The Booking Number must be provided')],
      validFromRules: [v => !!v || this.$t('Valid From must be provided')],
      validToRules: [v => !!v || this.$t('Valid To must be provided')],
    }
  },
  computed: {
    formatValidFrom() {
      return this.formatDate(this.ticket.validFrom)
    },
    formatValidTo() {
      return this.formatDate(this.ticket.validTo)
    },
    currentDate() {
      return this.dateToString(new Date())
    },
    minValidTo() {
      return this.ticket.validFrom
    },
    maxValidTo() {
      return this.dateToString(this.addDays(this.ticket.validFrom, 2))
    }
  },
  watch: {
    'ticket.validFrom'(newDateFrom) {
      this.ticket.validTo = this.dateToString(this.addDays(newDateFrom, 1))
    }
  },
  methods: {
    ...mapActions({
      createAndPrint: 'ticket/createAndPrint'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true

      this.createAndPrint(this.ticket)
        .finally(() => (this.loading = false))
        .then(blob => {
          this.downloadDocumentPayload(blob)
        })
        .catch(() => (this.errorSnackbar = true))
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    dateToString(date) {
      return date.toISOString().substr(0, 10)
    },
    allowedFromDates(val) {
      return new Date(val) >= this.currentDate
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    downloadDocumentPayload(blob) {
      const element = document.createElement('a');
      element.setAttribute('href', URL.createObjectURL(blob));
      element.setAttribute('download', 'ticket.pdf');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }
}
</script>
