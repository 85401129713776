<template>
  <v-app>
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img src="@/assets/img/bern-logo-splash.png" alt="Bern" width="130" height="40" />
                </div>
              </v-card-text>
              <v-form ref="form">
                <v-text-field
                  append-icon="person"
                  name="login"
                  :label="$t('Login')"
                  type="text"
                  v-model="userEmail"
                  :rules="[rules.required]"
                  :error="error"
                />
                <v-text-field
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="password"
                  :label="$t('Password')"
                  id="password"
                  v-model="password"
                  :rules="[rules.required]"
                  :error="error"
                  @keyup.enter="submit"
                  @click:append="hidePassword = !hidePassword"
                />
                <v-list>
                  <v-list-item>
                    <v-btn
                      block
                      color="#d0021b"
                      class="white--text"
                      @click="submit"
                      :loading="loading"
                      >{{ $t('Next') }}</v-btn
                    >
                  </v-list-item>
                </v-list>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar v-model="showResult" :timeout="2000" bottom>{{ result }}</v-snackbar>
    </v-content>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      hidePassword: true,
      userEmail: '',
      password: '',
      loading: false,
      error: false,
      showResult: false,
      result: '',
      rules: {
        required: value => !!value || this.$t('Required')
      }
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return
      }

      if (!this.userEmail || !this.password) {
        this.result = "Email and Password can't be null"
        this.showResult = true
        return
      }

      this.loading = true

      this.signIn({ userEmail: this.userEmail, password: this.password })
        .finally(() => {
          this.loading = false
        })
        .then(() => {
          return this.$router.push({ name: 'default' })
        })
        .catch(errorResult => {
          if (errorResult) {
            if (errorResult.message) {
              this.result = this.$t(errorResult.message)
            } else if (errorResult.errors) {
              this.result = errorResult.errors.map(e => this.$t(e.message)).join('\n\r')
            } else {
              this.result = errorResult.message || this.$t('Something went wrong...')
            }
          } else {
            this.result = this.$t('Something went wrong...')
          }

          this.showResult = true
        })
    }
  }
}
</script>
