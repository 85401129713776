import axios from 'axios'

const actions = {
  create({ context }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${window.__env.apiUrl}/api/tickets`, data)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  createAndPrint({ context }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${window.__env.apiUrl}/api/tickets/issue-print`, data, { responseType: 'blob' })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  getTickets(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${window.__env.apiUrl}/api/tickets`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  },
  invalidate(context, ticketId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${window.__env.apiUrl}/api/tickets/${ticketId}/invalidate`)
        .then(() => resolve())
        .catch(error => reject(error))
    })
  },
  uploadImportData(context, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${window.__env.apiUrl}/api/tickets/import`, formData)
        .then(reult => resolve(reult.data))
        .catch(error => reject(error))
    })
  }
}

export default {
  actions,
  namespaced: true
}
